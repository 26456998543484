<template>
  <div
    class="modal edit-lead-tracking-panel right-sliding-panel"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <modal-topbar>
          <a slot="left" v-on:click="closePanel">
            <i class="fas fa-chevron-left mr-1"></i>
            <span>{{ lead.username }}</span>
          </a>
          <div slot="center">Tracking</div>
        </modal-topbar>

        <div class="modal-body">
          <div class="main-content--tracking mt-4">
            <card v-for="link in contentPieces" :key="link.id" class="my-2">
              <input
                class="input-tracking_link px-3"
                v-model="trackingLinks[link.id]"
                v-on:keyup="onContentInputChange"
                type="text"
                v-on:focus="showAdd = false"
                v-on:blur="updateContentPieces"
              />
              <input
                class="input-cost px-3"
                type="number"
                v-model="costs[link.id]"
                v-on:keyup="onContentInputChange"
                v-on:change="onContentInputChange"
                min="0"
                v-on:focus="showAdd = false"
                v-on:blur="updateContentPieces"
              />
              <div class="label-currency">{{ campaign.currency }}</div>
              <a class="btn-delete" v-on:click="deleteContentPiece(link.id)" title="Delete this link" alt="Delete this link">
                <i class="fas fa-times-circle"></i>
              </a>
            </card>
            <card
              class="my-2"
              :style="
                inputError.trackingLink || inputError.cost
                  ? 'border-color: red;'
                  : ''
              "
            >
              <input
                id="input-tracking_link"
                class="input-tracking_link px-3"
                v-model="trackingLink"
                v-on:keyup="onNewContentInputChange"
                v-on:blur="addContentPiece"
                :placeholder="socials[lead.social] ? socials[lead.social].tracking_link_placeholder : ''"
                type="text"
              />
              <input
                id="input-cost"
                class="input-cost px-3"
                v-model="cost"
                v-on:keyup="onNewContentInputChange"
                v-on:blur="addContentPiece"
                type="number"
                min="0"
                value="0"
              />
              <div class="label-currency">{{ campaign.currency }}</div>
            </card>
            <card class="my-2" v-if="showAdd">
              <input
                class="input-tracking_link px-3"
                type="text"
                placeholder="...add another link"
              />
              <input class="input-cost px-3" type="number" min="0" value="0" />
              <div class="label-currency">{{ campaign.currency }}</div>
            </card>
          </div>
          <div class="flex-center mt-3">
            <base-button type="primary" class="btn-w-25" v-on:click="closePanel"
              >Close</base-button
            >
            <base-button
              v-if="showAdd"
              type="primary"
              class="btn-w-25"
              v-on:click="addContentPiece"
              >Add</base-button
            >
            <base-button
              v-else-if="changed"
              type="primary"
              class="btn-w-25"
              v-on:click="updateContentPieces"
              >Save</base-button
            >
            <base-button v-else type="outline-light" class="btn-w-25">Save</base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { MESSAGES, SOCIALS } from "@/constants";

export default {
  name: "edit-lead-tracking-panel",
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    username: {
      type: String
    },
    campaign: {
      type: Object
    },
    lead: {
      type: Object
    }
  },
  data() {
    return {
      loading: {
        links: false
      },
      trackingLink: "",
      socials: SOCIALS,
      cost: 0,
      trackingLinks: {},
      costs: {},
      changed: false,
      showAdd: false,
      inputError: {
        trackingLink: false,
        cost: false
      }
    };
  },
  computed: {
    ...mapState({
      contentPieces: state => state.campaign.content_pieces,
      showAlert: state => state.alert.showAlert
    }),
    isValidTrackingLink: {
      get() {
        return this.trackingLink ? true : false;
      }
    },
    isValidCost: {
      get() {
        return this.cost >= 0 ? true : false;
      }
    }
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".edit-lead-tracking-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    onContentInputChange() {
      this.changed = true;
    },
    onNewContentInputChange() {
      this.showAdd = true;
    },
    async updateContentPieces() {
      let failed = false;
      for (let i = 0; i < this.contentPieces.length; i++) {
        let contentPiece = { ...this.contentPieces[i] };
        const newURL = this.trackingLinks[contentPiece.id];
        const newCost = this.costs[contentPiece.id];
        if (
          (newURL !== contentPiece.url || newCost !== contentPiece.cost) &&
          !failed
        ) {
          contentPiece.url = newURL;
          contentPiece.cost = newCost;
          const res = await this.updateContentPiece(contentPiece);
          if (!res) {
            failed = true;
          }
        }
      }
      if (failed) {
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__update_lead_content_piece-failed"],
            alertClass: "danger"
          });
        }
      } else {
        this.changed = false;
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async addContentPiece() {
      await this.sleep(100);
      if (
        !this.showAdd ||
        document.activeElement === document.getElementById("input-cost") ||
        document.activeElement ===
          document.getElementById("input-tranking_link")
      )
        return;
      this.inputError.trackingLink = false;
      this.inputError.cost = false;
      if (!this.isValidTrackingLink) {
        this.inputError.trackingLink = true;
        this.showAdd = false;
        return;
      }
      if (!this.isValidCost) {
        this.inputError.cost = true;
        this.showAdd = false;
        return;
      }
      const sameLinks = this.contentPieces && this.contentPieces.length ? this.contentPieces.filter(e => e.url === this.trackingLink) : null;
      if (sameLinks && sameLinks.length) {
        this.inputError.trackingLink = true;
        this.showAdd = false;
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["campaign__duplicate_tracking_link"],
          alertClass: "danger"
        });
        return;
      }
      this.loading.links = true;
      try {
        await this.$store.dispatch("campaign/createLeadContentPiece", {
          campaign_id: this.campaign.id,
          lead_id: this.lead.id,
          url: this.trackingLink,
          cost: this.cost * 1
        });

        this.loading.links = false;
        this.cost = 0;
        this.trackingLink = "";
        this.showAdd = false;
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["campaign__create_lead_content_piece-success"],
          alertClass: "success"
        });
      } catch {
        this.inputError.trackingLink = true;
        this.showAdd = false;
        this.loading.links = false;
      }
    },
    async deleteContentPiece(contentId) {
      try {
        await this.$store.dispatch("campaign/deleteLeadContentPiece", {
          campaign_id: this.campaign.id,
          lead_id: this.lead.id,
          content_id: contentId
        });
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["campaign__delete_lead_content_piece-success"],
          alertClass: "success"
        });
      } catch {
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__delete_lead_content_piece-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async updateContentPiece(contentPiece) {
      try {
        await this.$store.dispatch(
          "campaign/updateLeadContentPiece",
          contentPiece
        );
        return true;
      } catch {
        return false;
      }
    }
  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;

      if (val) {
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".edit-lead-tracking-panel .modal-dialog"
          ).style.right = `0px`;
          document.querySelector("#input-tracking_link").focus();
        }, 0);
        this.trackingLink = null;
        this.cost = 0;
        this.showAdd = false;
        this.inputError.trackingLink = false;
        this.inputError.cost = false;
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".edit-lead-tracking-panel").scrollTop = 0;
      }
    },
    contentPieces(val) {
      val.forEach(e => {
        this.trackingLinks[e.id] = e.url;
        this.costs[e.id] = e.cost;
      });
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>

<style scope>
body {
 background-color: #CFD8DC;

}
</style>
<template>
  <div class="login-section row">
    <div class="card-body">
      <img class="img-auth" src="/app/img/icons/logoblack_ninja.png" />

      <div class="auth-form">
        <div class="">
          <div class="">
            <div class="title-auth text-center mb-4">Sign in</div>
            <form role="form" ref="loginForm">
              <base-input
                class="input-group-alternative auth-input mb-3"
                name="email"
                id="email"
                placeholder="Email"
                type="email"
                autofocus
                v-model="model.email"
              ></base-input>

              <base-input
                class="input-group-alternative auth-input"
                placeholder="Password"
                type="password"
                id="password"
                name="password"
                autocomplete="current-password"
                aria-autocomplete="current-password"
                v-model="model.password"
                v-on:keyup="passwordInputChanged($event)"
              ></base-input>

              <div class="col">
                <div class="text-danger text-center">
                  <small>{{ localError }}</small>
                </div>
              </div>
              <div class="btn-cont">
                <base-button
                  type="primary"
                  class="btn-signup mt-4"
                  :disabled="loading"
                  @click="gotoSignUp()"
                  >Sign Up</base-button
                >
                <base-button
                  type="primary"
                  class="btn-auth mt-4"
                  :disabled="loading"
                  @click="submit()"
                  >Sign in</base-button
                >
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="auth-bottom mt-3">
        <div class="col-12 text-right">
          <a href="app/register_forgot" class="text-black">
            <small>Forgot password?</small>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { MESSAGES } from "@/constants";

export default {
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
        shop: "",
        state: "",
        shopify_success: 0
      },
      localError: null,
      loading: false
    };
  },
  computed: {
    ...mapState({
      showAlert: (state) => state.alert.showAlert,
      authUser: (state) => state.user.authUser
    })
  },
  watch: {
    $route: function () {
      this.setRouter()
    }
  },
  mounted() {
    // to reload userpilot
    this.setRouter();
    this.$store.dispatch("user/setRoute", this.$route.path);
    this.model.shop = this.$route.query.shop;
    this.model.state = this.$route.query.state;
    this.model.shopify_success = this.$route.query.shopify_success;
  },
  methods: {
    passwordInputChanged(e) {
      if (e.keyCode === 13) {
        this.submit();
      }
    },
    async submit() {
      const { email, password } = this.model;
      if (!email) {
        this.localError = MESSAGES["user__email-blank"];
        return;
      }
      if (!password) {
        this.localError = MESSAGES["user__password-blank"];
        return;
      }
      this.loading = true;
      try {
        const res = await this.$store.dispatch("user/signIn", this.model);
        this.loading = false;
        this.localError = null;
        if (res) {
          const shop = res.shop;
          const state = res.state;
          const shopify_success = res.shopify_success;
          if (shopify_success) {
            window.location.replace(`/app/settings/integrations`);
          } else if (shop) {
            window.location.replace(
              `/app/settings/integrations/shopify?shop=${shop}${
                state ? "&state=" + state : ""
              }`
            );
          } else {
            window.location.replace("/app/home");
          }
        }
      } catch {
        this.loading = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__login-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    gotoSignUp() {
      this.$router.push("/app/signup");
    }
  }
};
</script>
<style lang="scss">
@import "../assets/scss/pages/login";
</style>

<template>
  <div class="stripe-card credit-card-inputs">
    <ul v-if="paymentMethod" class="panel-blocks mt-5">
      <li class="panel-item mt-2 d-flex">
        <div class="card-icon">
          <svg
            class="ccicon-big"
            width="750"
            height="471"
            viewBox="0 0 750 471"
            version="1.1"
            v-html="getCardIcon(paymentMethod.brand)"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          />
        </div>
        <div class="d-flex ml-3 mt-2 flex-column">
          <div class="ml-2 my-1">
            {{ paymentMethod.cardholder }} {{ paymentMethod.last4 }}
          </div>
          <div class="ml-2 my-1">
            <span
              >{{ paymentMethod.exp_month }} /
              {{ paymentMethod.exp_year }}</span
            >
          </div>
        </div>
      </li>
      <li
        v-if="loading.pay || loading.remove"
        class="m-4 flex-center align-item-center"
      >
        <base-spinner></base-spinner>
        <span class="ml-2 mt-2">Waiting for the card network response...</span>
      </li>
      <li v-else class="panel-item mt-4 flex-center">
        <base-button
          type="danger"
          class="btn-w-25 mr-4"
          @click="removePaymentMethod(paymentMethod.card_id)"
          >Remove</base-button
        >
        <base-button
          type="primary"
          class="btn-w-25"
          @click="pay(paymentMethod.card_id, false)"
          >Pay ${{ cost }}</base-button
        >
      </li>
    </ul>
    <ul v-show="!paymentMethod" class="panel-blocks mt-5">
      <li class="panel-item mt-2">
        <div class="mb-2 setting-label mt-1">
          <span class="nav-link-text">Cardholder Name</span>
        </div>
        <base-input
          id="input_cardholder"
          class="input-group-alternative mb-3"
          placeholder="Cardholder"
          type="text"
          v-model="cardHolder"
          v-on:keyup.enter="submitFormToCreateToken"
          autofocus
        ></base-input>
      </li>
      <li class="panel-item mt-2 flex-column">
        <div class="mb-2 setting-label mt-1">
          <span class="nav-link-text">Card Number</span>
        </div>
        <div
          id="card-number"
          class="stripe-input"
          :class="{ 'stripe-form-danger': cardNumberError }"
        ></div>
        <span class="help-block" v-if="cardNumberError">{{
          cardNumberError
        }}</span>
        <div class="card-icon">
          <svg
            id="ccicon"
            class="ccicon-absolute"
            width="750"
            height="471"
            viewBox="0 0 750 471"
            version="1.1"
            v-html="getCardIcon(card.brand)"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          />
        </div>
      </li>
      <li class="panel-item mt-2 row">
        <div class="flex-column col-6">
          <div class="mb-2 setting-label mt-1">
            <span class="nav-link-text">Expire Date MM/YY</span>
          </div>
          <div
            id="card-expiry"
            class="stripe-input"
            :class="{ 'stripe-form-danger': cardExpiryError }"
          ></div>
          <span class="help-block" v-if="cardExpiryError">{{
            cardExpiryError
          }}</span>
        </div>
        <div class="flex-column col-6">
          <div class="mb-2 setting-label mt-1">
            <span class="nav-link-text">CVC</span>
          </div>

          <div
            id="card-cvc"
            class="stripe-input"
            :class="{ 'stripe-form-danger': cardCvcError }"
          ></div>
          <span class="help-block" v-if="cardCvcError">{{ cardCvcError }}</span>
        </div>
      </li>
      <li
        v-if="loading.pay"
        class="m-4 flex-center align-item-center"
      >
        <base-spinner></base-spinner>
        <span class="ml-2 mt-2">Waiting for the card network response...</span>
      </li>
      <li v-else class="panel-item mt-4 flex-center">
        <base-button
          v-if="cost"
          type="primary"
          class="btn-w-25"
          @click="submitFormToCreateToken()"
          >Pay ${{ cost }}</base-button
        >
        <base-button
          v-else
          type="primary"
          class="btn-w-25"
          @click="submitFormToCreateToken()"
          >Add card</base-button
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { MESSAGES } from "@/constants";
import * as CONFIGS from "../configs"
import * as ICONS from "../constants/icons";

export default {
  name: "stripe-form",
  props: {
    package: Object,
    plan: Object,
    paymentMethod: Object
  },
  data() {
    return {
      icons: ICONS,
      cardHolder: "",

      card: {
        cvc: "",
        number: "",
        expiry: "",
        brand: ""
      },

      //elements
      cardNumber: "",
      cardExpiry: "",
      cardCvc: "",
      stripe: null,

      // errors
      stripeError: "",
      cardCvcError: "",
      cardExpiryError: "",
      cardNumberError: "",
      loading: {
        form: false,
        pay: false,
        remove: false
      }
    };
  },
  computed: {
    ...mapState({
      showAlert: state => state.alert.showAlert
    }),
    cost: {
      get() {
        return (this.package || this.plan).cost / 100;
      }
    }
  },
  mounted() {
    this.setUpStripe();
  },
  methods: {
    setUpStripe() {
      if (window.Stripe === undefined) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["user__stripe_v3-loading_error"],
          alertClass: "danger"
        });
      } else {
        const stripeStyle = {
          base: {
            fontSize: "16px",
            color: "#32325d",
            "::placeholder": {
              color: "#8999ab"
            }
          }
        };
        const stripe = window.Stripe(CONFIGS.services.stripe.public_key);
        this.stripe = stripe;

        const elements = stripe.elements();
        this.cardCvc = elements.create("cardCvc", { style: stripeStyle });
        this.cardExpiry = elements.create("cardExpiry", {
          style: stripeStyle
        });
        this.cardNumber = elements.create("cardNumber", {
          style: stripeStyle
        });

        this.cardCvc.mount("#card-cvc");
        this.cardExpiry.mount("#card-expiry");
        this.cardNumber.mount("#card-number");

        this.listenForErrors();
      }
    },
    listenForErrors() {
      const vm = this;
      this.cardNumber.addEventListener("change", event => {
        vm.toggleError(event);
        vm.cardNumberError = "";
        vm.card.number = event.complete ? true : false;
        vm.card.brand = event.brand;
      });

      this.cardExpiry.addEventListener("change", event => {
        vm.toggleError(event);
        vm.cardExpiryError = "";
        vm.card.expiry = event.complete ? true : false;
      });

      this.cardCvc.addEventListener("change", event => {
        vm.toggleError(event);
        vm.cardCvcError = "";
        vm.card.cvc = event.complete ? true : false;
      });
    },

    getCardIcon(brand) {
      if (brand) {
        return this.icons[brand] ? this.icons[brand] : this.icons["undefined"];
      }
      return this.icons["unknown"];
    },

    toggleError(event) {
      if (event.error) {
        this.stripeError = event.error.message;
      } else {
        this.stripeError = "";
      }
    },

    submitFormToCreateToken() {
      this.clearCardErrors();
      let valid = true;

      if (!this.card.number) {
        valid = false;
        this.cardNumberError = MESSAGES["user__card_number-invalid"];
      }
      if (!this.card.cvc) {
        valid = false;
        this.cardCvcError = MESSAGES["user__card_cvc-invalid"];
      }
      if (!this.card.expiry) {
        valid = false;
        this.cardExpiryError = MESSAGES["user__card_expiry-invalid"];
      }
      if (this.stripeError) {
        valid = false;
      }
      if (valid) {
        this.createToken();
      }
    },

    createToken() {
      this.loading.pay = true;
      this.stripe.createToken(this.cardNumber).then(result => {
        if (result.error) {
          this.stripeError = result.error.message;
          this.loading.pay = false;
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: this.stripeError,
            alertClass: "danger"
          });
        } else {
          const token = result.token.id;
          this.pay(token, true);
        }
      });
    },

    clearElementsInputs() {
      this.cardCvc.clear();
      this.cardExpiry.clear();
      this.cardNumber.clear();
    },

    clearCardErrors() {
      this.stripeError = "";
      this.cardCvcError = "";
      this.cardExpiryError = "";
      this.cardNumberError = "";
    },
    pay(token, isToken) {
      this.loading.pay = true;
      if (this.plan) {
        this.startSubscription(token, isToken);
      } else if (this.package.cost) {
        this.buyCredits(token, isToken);
      } else {
        this.addNewPaymentMethod(token, isToken);
      }
    },
    async removePaymentMethod(cardId) {
      this.loading.remove = true;
      try {
        await this.$store.dispatch("billing/removePaymentMethod", cardId);
        this.loading.remove = false;

        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES['user__remove_payment_method-success'],
          alertClass: "success"
        });
        this.$emit("removedPaymentMethod", cardId);
      } catch {
        this.loading.remove = false;

        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES['common-500'],
            alertClass: "danger"
          });
        }
        this.$emit("removedPaymentMethod", null);
      }
    },
    async buyCredits(token, isToken) {
      try {
        const data = await this.$store.dispatch("billing/buyCredits", {
          package_id: this.package.id,
          cardholder: this.cardHolder,
          brand: this.card.brand,
          identifier: token,
          is_token: isToken
        });
        this.loading.pay = false;

        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["user__buying_credits-success"],
          alertClass: "success"
        });

        this.$emit("boughtCredits", data);
      } catch {
        this.loading.pay = false;

        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__buying_credits-failed"],
            alertClass: "danger"
          });
        }

        this.$emit("boughtCredits", null);
      }
    },
    async startSubscription(token, isToken) {
      try {
        await this.$store.dispatch("billing/startSubscription", {
          plan_id: this.plan.id,
          cardholder: this.cardHolder,
          brand: this.card.brand,
          identifier: token,
          is_token: isToken
        });
        this.loading.pay = false;
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["user__subscription-success"],
          alertClass: "success"
        });

        this.$emit("startSubscription", true);
      } catch {
        this.loading.pay = false;

        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__subscription-failed"],
            alertClass: "danger"
          });
        }
        /* Need to update this function name */
        this.$emit("startSubscription", false);
      }
    },
    async addNewPaymentMethod(token, isToken) {
      try {
        const {data} = await this.$store.dispatch("billing/addNewPaymentMethod", {
          brand: this.card.brand,
          identifier: token,
          is_token: isToken
        });
        this.loading.pay = false;

        /* Need to update message */
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: "Card added successfully!",
          alertClass: "success"
        });

        this.$emit("addedNewPaymentMethod", data);
      } catch {
        this.loading.pay = false;
        /* Need to update message */
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: "Adding new card fail!",
            alertClass: "danger"
          });
        }

        this.$emit("addedNewPaymentMethod", null);
      }
    }

  }
};
</script>
